<template>
    <div>
        <div id="Gmapspc"></div>
        <remote-js :jsUrl="jsUrl" :js-load-call-back="loadRongJs"></remote-js>
    </div>
</template>
<script>
import {web_inter} from '@/untils/common';
import RemoteJs from '@/components/map/remote'
export default {
    components: { RemoteJs },
    props: {
        mapData: {
            type: Object,
            required: true
        },
        shopList:{
            type:Array,
            required:true   
        },
        center:{
            type:Object,
            required:true   
        },
        paramsMap:{
            type:Object,
            required:true   
        },
    },
    watch:{
        shopList(){
            this.load()
        },
        center(){
            this.load()
        },
        paramsMap(){
            this.load()
        },
    },
    computed:{
       
    },
    data(){
        return{
            jsUrl:`https://maps.googleapis.com/maps/api/js?key=${web_inter.google.key}`
        }
    },
    methods: {
        load(){
            if(this.center&&this.paramsMap){
                this.loadRongJs(this.center,this.paramsMap);
            }
        },
        loadRongJs(data,paramsMap) {
            let that = this;
            let docmap = document.getElementById("Gmapspc");
            let center = {};
            if(data&&paramsMap&&paramsMap.location){
                center = { lat: paramsMap.location.lat(), lng: paramsMap.location.lng() }
            }else{
                center = { lat: that.mapData.latitude, lng: that.mapData.longitude}
            }
            if(that.shopList.length>0){
                if(center.lat&&center.lng){
                    let map = new google.maps.Map(docmap, {
                        center: center,
                        zoom: 16,
                        // 动态从调用组件传递经纬度
                        disableDefaultUI: false,
                        zoomControl: true
                    });
                    for(let i = 0; i < that.shopList.length; i++){
                        var geocoder = new google.maps.Geocoder();//创建geocoder服务
                        geocoder.geocode( { 'address': that.shopList[i].address}, function(results, status) {
                            if (status == google.maps.GeocoderStatus.OK) {
                                map.setCenter(results[0].geometry.location);
                                const marker = new google.maps.Marker({
                                    position: results[0].geometry.location,
                                    icon: 'http://maps.google.com/mapfiles/marker.png',
                                    // icon: require('@/assets/images/icon_dw_map.png'),
                                    width: '10px',
                                    height: '10px',
                                });
                                marker.setMap(map);
                                let infowindow = new google.maps.InfoWindow();
                                google.maps.event.addListener(marker, 'click', function() {
                                    infowindow = new google.maps.InfoWindow({
                                        content:that.shopList[i].address,
                                        maxWidth:160,
                                        pixelOffSet: new google.maps.Size(0,20)
                                    });
                                    infowindow.open(map,marker);
                                });
                                if(paramsMap&&paramsMap.item){
                                    for (let j = 0; j < that.shopList.length; j++) {
                                        if(i == j && data.id==that.shopList[j].id){
                                            infowindow = new google.maps.InfoWindow({
                                                content:that.shopList[i].address,
                                                maxWidth:160,
                                                pixelOffSet: new google.maps.Size(0,20)
                                            });
                                            infowindow.open(map,marker);
                                        }else{
                                            infowindow.close();
                                        }
                                    }
                                }
                            } else {
                                console.log('pc-地址查询失败，地址信息不准确');
                                console.log('pc-Geocode was not successful for the following reason: ' + status);
                            }
                        });
                    }
                }
            }
        },
    },
    created(){
        
    }
}
</script>

<style lang="scss" scoped>
    #Gmapspc {
        width: 100%;
        height:100%;
        background: #eee;
    }
</style>

